import React from 'react'
import { Container, Wrapper, Title, Desc, CardContainer} from './ReferenceStyle'
import ReferenceCard from '../Cards/ReferenceCard'
import { references } from '../../data/constants'
import ConfettiComponents from '../../themes/Confetti'


const Projects = () => {
  return (
    <Container id="reference">
    <ConfettiComponents/>
      <Wrapper>
        <Title>Refrence</Title>
        <Desc> 
          I have worked on a wide range of projects. From web apps to android apps. Here are some of my projects.
        </Desc>
        <CardContainer>
        {references.map((reference) => (
              <ReferenceCard reference={reference}/>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Projects
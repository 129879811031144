import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
    width: 330px;
    height: auto;
    background-color: ${({ theme }) => theme.card};
    z-index: 5;
    border-radius: 10px;
    box-shadow: 0 0 12px 4px rgba(0,0,0,0.4);
    padding: 26px 20px;
    flex-direction: column;
    gap: 14px;
    transition: all 0.5s ease-in-out;
    backdrop-filter: blur(4px);
    position: relative;
`
const Role = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.primary + 15};
    padding: 2px 8px;
    border-radius: 10px;
    margin-bottom: 5px;

`
const Image = styled.img`
position: relative;
object-fit:contain;
width: 100%;
height: 100%;
max-width: 400px;
max-height: 400px;
border-radius: 50%;
border: 2px solid ${({ theme }) => theme.primary};

@media (max-width: 768px) {
  max-width: 400px;
  max-height: 400px;
}

@media (max-width: 640px) {
  max-width: 280px;
  max-height: 280px;
}

`
const Details = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px 2px;
`
const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.text_secondary};
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
`
const Date = styled.div`
    margin-bottom: 5px;
    font-size: 14px;
    margin-left: 2px;
    font-weight: 600;
    color: ${({ theme }) => theme.text_secondary + 80};
    @media only screen and (max-width: 768px){
        font-size: 10px;
    }
`
const Description = styled.div`
    font-weight: 400;
    color: ${({ theme }) => theme.text_secondary + 99};
    overflow: hidden;
    margin-top: 8px;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
`

const ProjectCards = ({reference}) => {
    return (
        <Card>
            <Image src={reference.image}/>
            <Details>
                <Title>{reference.title}</Title>
                <Role>{reference.role}</Role>
                <Date>Email : {reference.date}</Date>
                <Date>Phone : {reference.phone}</Date>
            </Details>
            {/* <Button>View Project</Button> */}
        </Card>
    )
}

export default ProjectCards